import "../styles/reports.css";
import { useState, useEffect } from "react";
import LoadingScreen, { showLoadingScreen, hideLoadingScreen }  from "../components/LoadingScreen.js";
import dayjs from "dayjs";

export default function Reports() {

    const [ appointmentCountByMonth, setAppointmentCountByMonth ] = useState([]);
    const [ appointmentCountByQuarter, setAppointmentCountByQuarter ] = useState([]);
    const [ appointmentCountTotal, setAppointmentCountTotal ] = useState();

    const [ nhimaAppointmentCountByMonth, setNhimaAppointmentCountByMonth ] = useState([]);
    const [ nhimaAppointmentCountByQuarter, setNhimaAppointmentCountByQuarter ] = useState([]);
    const [ nhimaAppointmentCountTotal, setNhimaAppointmentCountTotal ] = useState();

    const [ treatmentsCountForPatientsAged0To1, setTreatmentsCountForPatientsAged0To1 ] = useState([]);
    const [ treatmentsCountForPatientsAged1To4, setTreatmentsCountForPatientsAged1To4 ] = useState([]);
    const [ treatmentsCountForPatientsAged5To14, setTreatmentsCountForPatientsAged5To14 ] = useState([]);
    const [ treatmentsCountForPatientsAged15To120, setTreatmentsCountForPatientsAged15To120] = useState([]);

    const [ patientCountAged0To5, setPatientCountAged0To5 ] = useState([]);
    const [ patientCountAged5To200, setPatientCountAged5To200 ] = useState([]);
    const [ patientCountTotal, setPatientCountTotal ] = useState([]);

    const [ chosenMonth, setChosenMonth ] = useState(new Date().getMonth());
    const [ chosenYear, setChosenYear ] = useState(new Date().getFullYear());

    const monthsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const api = {
        getAppointmentCountForAllPatients(year) {
            const url = `/reports/all-appointments/${year}`;
            return fetch(url).then(res => res.json());
        },
        getAppointmentCountForNhimaPatients(year) {
            const url = `/reports/nhima-appointments/${year}`;
            return fetch(url).then(res => res.json());
        },
        getTreatmentsCount(year, month) {
            const url = `/reports/count-treatments?year=${year}&month=${month}`;
            return fetch(url).then(res => res.json());
        },
        getCountPatients(year) {
            const url = `/reports/count-patients?year=${year}`;
            return fetch(url).then(res => res.json());
        }
    };
    
    const controller = {
        getData(selectedYear) {
            showLoadingScreen();
            Promise.all([
                controller.getDataForAllPatients(selectedYear),
                controller.getDataForNhimaPatients(selectedYear),
                controller.getTreatmentCounts(selectedYear, chosenMonth),
                controller.getCountPatients(selectedYear)
            ])
                .then(() => hideLoadingScreen());
        },
        getDataForAllPatients(selectedYear) {
            return api.getAppointmentCountForAllPatients(selectedYear)
                .then(res => {
                    setAppointmentCountByMonth(res[0]);
                    setAppointmentCountByQuarter(res[1]);
                    setAppointmentCountTotal(res[2][0].total);
                });
        },
        getDataForNhimaPatients(selectedYear) {
            return api.getAppointmentCountForNhimaPatients(selectedYear)
                .then(res => {
                    setNhimaAppointmentCountByMonth(res[0]);
                    setNhimaAppointmentCountByQuarter(res[1]);
                    setNhimaAppointmentCountTotal(res[2][0].total);
                });
        },
        getTreatmentCounts(year, month) {
            return api.getTreatmentsCount(year, month)
                .then(res => {
                    setTreatmentsCountForPatientsAged0To1(res[0]);
                    setTreatmentsCountForPatientsAged1To4(res[1]);
                    setTreatmentsCountForPatientsAged5To14(res[2]);
                    setTreatmentsCountForPatientsAged15To120(res[3]);
                    
                    });
        },
        getCountPatients(year) {
            return api.getCountPatients(year)
                .then(res => {
                    setPatientCountAged0To5(res[0]);
                    setPatientCountAged5To200(res[1]);
                    setPatientCountTotal(res[2]);
                });
        },
        handleYearChange(e) {
            e.preventDefault();
            const selectedYear = e.target.value;
            setChosenYear(selectedYear);
            controller.getData(selectedYear);
        },
        handleMonthChange(e) {
            e.preventDefault();
            setChosenMonth(e.target.value);
            showLoadingScreen();
            controller.getTreatmentCounts(chosenYear, e.target.value)
                .then(() => hideLoadingScreen());
        },
    };

    const display = {
        loopThroughYears() {
            const currentYear = new Date().getFullYear();
        },
        getMonthNameInEnglish(monthNumber) {
            const monthName = dayjs(`2023-${monthNumber}-01`).format("MMMM");
            return monthName;
        },
        getTreatmentsCountTableBody() {
            let index = -1;
            return treatmentsCountForPatientsAged0To1.map(item => {
                index++;
                return <tr>
                    <td>
                        { item.treatment }
                    </td>
                    <td>
                        { treatmentsCountForPatientsAged0To1[index].count }
                    </td>
                    <td>
                        { treatmentsCountForPatientsAged1To4[index].count }
                    </td>
                    <td>
                        { treatmentsCountForPatientsAged5To14[index].count }
                    </td>
                    <td>
                        { treatmentsCountForPatientsAged15To120[index].count }
                    </td>
                </tr>
            })
        }
    }

    useEffect(() => {
        controller.getData(chosenYear);
    }, []);

    return (
        <section className="reportsPage section">
            <div className="contentContainer">

                <div className="yearAndMonthPickerContainer">

                    <div className="labelAndPickerContainer">
                        <label htmlFor="chooseYearSelect">Choose Year: </label>
                        <select
                            className="inputField" 
                            id="chooseYearSelect" 
                            name="chooseYearSelect"
                            onChange = { controller.handleYearChange } >
                                <option hidden value=""></option>
                                <option value={ new Date().getFullYear() - 1 }> { new Date().getFullYear() - 1 }</option>
                                <option value={ new Date().getFullYear() }> { new Date().getFullYear() }</option>
                        </select> 
                    </div>

                    <div className="labelAndPickerContainer">
                        <label htmlFor="chooseMonthSelect">Choose Month: </label>
                        <select
                            className="inputField" 
                            id="chooseMonthSelect" 
                            name="chooseMonthSelect"
                            onChange = { controller.handleMonthChange } >
                                <option hidden value=""></option>
                                { monthsArray.map(monthNumber => <option value = { monthNumber }> { monthNumber } </option>) }
                        </select> 
                    </div>

                </div>

                <div className="tableAndHeaderContainer appointments">
                    <h3>Appointment Count For { chosenYear }</h3>
                    <table className="table appointments">
                        <thead>
                            <tr className="headerRow">
                                <th> </th>
                                <th>January</th>
                                <th>February</th>
                                <th>March</th>
                                <th>April</th>
                                <th>May</th>
                                <th>June</th>
                                <th>July</th>
                                <th>August</th>
                                <th>September</th>
                                <th>October</th>
                                <th>November</th>
                                <th>December</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan="2">Amount of All appointments</td>
                                {
                                    appointmentCountByMonth.map(item => <td>{ item.appointmentCount }</td>)
                                }
                                <td rowSpan="2">
                                    { appointmentCountTotal }
                                </td>
                            </tr>
                            <tr>
                                {
                                    appointmentCountByQuarter.map(item => <td colSpan="3">{ item.appointmentCount }</td>)
                                }
                            </tr>
                            <tr>
                                <td rowSpan="2">Amount of Nhima only appointments</td>
                                {
                                    nhimaAppointmentCountByMonth.map(item => <td>{ item.appointmentCount }</td>)
                                }
                                <td rowSpan="2">
                                    { nhimaAppointmentCountTotal }
                                </td>
                            </tr>
                            <tr>
                                {
                                    nhimaAppointmentCountByQuarter.map(item => <td colSpan="3">{ item.appointmentCount }</td>)
                                }
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div className="tableAndHeaderContainer treatments">
                    <h3>Treatment Count For { display.getMonthNameInEnglish(chosenMonth) } { chosenYear } </h3>
                    <div className="tableContainer">
                        <table className="table treatment">
                            <thead>
                                <tr className="headerRow">
                                    <th> </th>
                                    <th>
                                        0 - 1
                                    </th>
                                    <th>
                                        1 - 4
                                    </th>
                                    <th>
                                        5 - 14
                                    </th>
                                    <th>
                                        15 - ..
                                    </th>

                                </tr> 
                            </thead>
                            <tbody>
                                { display.getTreatmentsCountTableBody() }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="tableAndHeaderContainer patients">

                    <h3>Patient Count For { chosenYear }</h3>

                    <table className="table patientCount">
                        <thead>
                            <tr className="headerRow">
                                <th> </th>
                                <th>0 - 4</th>
                                <th>5 - ..</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> Patients </td>
                                <td> { patientCountAged0To5 } </td>
                                <td> { patientCountAged5To200 } </td>
                                <td> { patientCountTotal } </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <LoadingScreen />
        </section>
    );
}