import "../styles/analytics.css";
import { useState, useEffect } from "react";
import LoadingScreen, { showLoadingScreen, hideLoadingScreen }  from "../components/LoadingScreen.js";
import dayjs from "dayjs";

export default function Analytics() {

    const [ totalSumsForPayments, setTotalSumsForPayments ] = useState([]); 
    const [ monthlySumsForPayments, setMonthlySumsForPayments ] = useState([]); 

    const [ totalSumsForDoctors, setTotalSumsForDoctors ] = useState([]); 
    const [ monthlySumsForDoctors, setMonthlySumsForDoctors ] = useState([]); 

    const [ totalSumsForTreatments, setTotalSumsForTreatments ] = useState([]); 
    const [ monthlySumsForTreatments, setMonthlySumsForTreatments ] = useState([]); 

    const [ totalMonthlySums, setTotalMonthlySums ] = useState([]); 
    const [ totalSumForYear, setTotalSumForYear ] = useState([]); 

    const [ totalDailySums, setTotalDailySums ] = useState([]); 

    const [ chosenMonth, setChosenMonth ] = useState(new Date().getMonth());
    const [ chosenYear, setChosenYear ] = useState(new Date().getFullYear());

    const monthsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const api = {
        getSumsForPayments(year) {
            const fetchURL = `/analytics/sums?year=${year}&category=payment`;
            return fetch(fetchURL).then(res => res.json());
        },
        getSumsForDoctors(year) {
            const fetchURL = `/analytics/sums?year=${year}&category=doctor`;
            return fetch(fetchURL).then(res => res.json());
        },
        getSumsForTreatments(year) {
            const fetchURL = `/analytics/sums?year=${year}&category=treatment`;
            return fetch(fetchURL).then(res => res.json());
        },
        getTotalDailySums(year, month) {
            const fetchURL = `/analytics/sums/daily?year=${year}&month=${month}`;
            return fetch(fetchURL).then(res => res.json());
        }, 
    };

    const controller = {
        getAllData(year, month) {
            showLoadingScreen();
            Promise.all([
                controller.getSumsForPayments(year),
                controller.getSumsForDoctors(year),
                controller.getSumsForTreatments(year),
                controller.getTotalDailySums(year, month),
            ])
                .then(() => hideLoadingScreen());
        },
        getSumsForPayments(year) {
            return api.getSumsForPayments(year)
                .then(res => {
                    setMonthlySumsForPayments(res.monthlySumsForCategory);
                    setTotalSumsForPayments(res.totalYearlySumsForCategory);
                    setTotalMonthlySums(res.totalMonthlySums);
                    setTotalSumForYear(res.totalSumForYear);
                });
        },
        getSumsForDoctors(year) {
            return api.getSumsForDoctors(year)
                .then(res => {
                    setMonthlySumsForDoctors(res.monthlySumsForCategory);
                    setTotalSumsForDoctors(res.totalYearlySumsForCategory);
                });
        },
        getSumsForTreatments(year) {
            return api.getSumsForTreatments(year)
                .then(res => {
                    setMonthlySumsForTreatments(res.monthlySumsForCategory);
                    setTotalSumsForTreatments(res.totalYearlySumsForCategory);
                });
        },
        getTotalDailySums(year, month) {
            return api.getTotalDailySums(year, month)
                .then(res => {
                    setTotalDailySums(res);
                });
        },
        handleYearChange(e) {
            e.preventDefault();
            setChosenYear(e.target.value);
            controller.getAllData(e.target.value, chosenMonth);
        },
        handleMonthChange(e) {
            e.preventDefault();
            setChosenMonth(e.target.value);
            controller.getAllData(chosenYear, e.target.value);
        },
    };

    const display = {
        getMonthNameInEnglish(monthNumber) {
            const monthName = dayjs(`2023-${monthNumber}-01`).format("MMMM");
            return monthName;
        },
    }

    useEffect(() => {
        controller.getAllData(chosenYear, chosenMonth);
    }, []);

    return (
        <section className="analyticsPage section">
            <div className="contentContainer">

                <div className="yearAndMonthPickerContainer">

                    <div className="labelAndPickerContainer">
                        <label htmlFor="chooseYearSelect">Choose Year: </label>
                        <select
                            className="inputField" 
                            id="chooseYearSelect" 
                            name="chooseYearSelect"
                            onChange = { controller.handleYearChange } >
                                <option hidden value=""></option>
                                <option value={ new Date().getFullYear() - 1 }> { new Date().getFullYear() - 1 }</option>
                                <option value={ new Date().getFullYear() }> { new Date().getFullYear() }</option>
                        </select> 
                    </div>

                    <div className="labelAndPickerContainer">
                        <label htmlFor="chooseMonthSelect">Choose Month: </label>
                        <select
                            className="inputField" 
                            id="chooseMonthSelect" 
                            name="chooseMonthSelect"
                            onChange = { controller.handleMonthChange } >
                                <option hidden value=""></option>
                                { monthsArray.map(monthNumber => <option value = { monthNumber }> { monthNumber } </option>) }
                        </select> 
                    </div>

                </div>

                <div className="tableAndHeaderContainer">
                    <h3>Daily Figures</h3>
                    <div className="tableContainer">

                        <table className="table total daily">
                            <thead>
                                <tr className="headerRow">
                                    <th> { display.getMonthNameInEnglish(chosenMonth) } </th>
                                    {
                                        totalDailySums.map(item => <th> { item.day} </th>)
                                    }
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="categoryCell" > Sum </td>
                                    {
                                        totalDailySums.map(item => <td>{ item.total }</td>)
                                    }
                                    <td className="totalCell" > { totalMonthlySums[chosenMonth - 1] ? totalMonthlySums[chosenMonth - 1].total: "" } </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="tableAndHeaderContainer">
                    <h3>Monthly Figures by Payment</h3>
                    <div className="tableContainer">
                        <table className="table payments">
                            <thead>
                                <tr className="headerRow">
                                    <th> </th>
                                    <th>January</th>
                                    <th>February</th>
                                    <th>March</th>
                                    <th>April</th>
                                    <th>May</th>
                                    <th>June</th>
                                    <th>July</th>
                                    <th>August</th>
                                    <th>September</th>
                                    <th>October</th>
                                    <th>November</th>
                                    <th>December</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                { totalSumsForPayments.map(item => 
                                    <tr>
                                        <td className="categoryCell"> { item.payment } </td>
                                        { monthlySumsForPayments[totalSumsForPayments.indexOf(item)].map(item => <td> { item.sum } </td> )}
                                        <td className="totalCell"> { item.total } </td>
                                    </tr>
                                )}
                                <tr className="footerRow">
                                    <td>Total</td>
                                    { totalMonthlySums.map(item => <td>{ item.total }</td>)}
                                    <td>{ totalSumForYear }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="tableAndHeaderContainer">
                    <h3>Monthly Figures by Doctor</h3>
                    <div className="tableContainer">
                        <table className="table doctors">
                            <thead>
                                <tr className="headerRow">
                                    <th> </th>
                                    <th>January</th>
                                    <th>February</th>
                                    <th>March</th>
                                    <th>April</th>
                                    <th>May</th>
                                    <th>June</th>
                                    <th>July</th>
                                    <th>August</th>
                                    <th>September</th>
                                    <th>October</th>
                                    <th>November</th>
                                    <th>December</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                { totalSumsForDoctors.map(item => 
                                    <tr>
                                        <td className="categoryCell"> { item.doctor } </td>
                                        { monthlySumsForDoctors[totalSumsForDoctors.indexOf(item)].map(item => <td> { item.sum } </td> )}
                                        <td className="totalCell"> { item.total } </td>
                                    </tr>
                                )}
                                <tr className="footerRow">
                                    <td>Total</td>
                                    { totalMonthlySums.map(item => <td>{ item.total }</td>)}
                                    <td>{ totalSumForYear }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="tableAndHeaderContainer">
                    <h3>Monthly Figures by Treatment</h3>
                    <div className="tableContainer">
                        <table className="table treatment">
                            <thead>
                                <tr className="headerRow">
                                    <th> </th>
                                    <th>January</th>
                                    <th>February</th>
                                    <th>March</th>
                                    <th>April</th>
                                    <th>May</th>
                                    <th>June</th>
                                    <th>July</th>
                                    <th>August</th>
                                    <th>September</th>
                                    <th>October</th>
                                    <th>November</th>
                                    <th>December</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                { totalSumsForTreatments.map(item => 
                                    <tr>
                                        <td className="categoryCell"> { item.treatment } </td>
                                        { monthlySumsForTreatments[totalSumsForTreatments.indexOf(item)].map(item => <td> { item.sum } </td> )}
                                        <td className="totalCell"> { item.total } </td>
                                    </tr>
                                )}
                                <tr className="footerRow">
                                    <td>Total</td>
                                    { totalMonthlySums.map(item => <td>{ item.total }</td>)}
                                    <td>{ totalSumForYear }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <LoadingScreen />

           </div>
        </section>
    );
}